import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { Fade, AttentionSeeker, Zoom } from "react-awesome-reveal";
import { MdWavingHand } from "react-icons/md";
import WIPSVG from "~/assets/svg/wip";
import sabahossein from "~/assets/pdf/sabahossein.pdf"
export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            aboutme
          }
    }
`;

const MainText = styled.p`
    font-size: 3em;
    color: #d6d4d4;
    font-family: "Roboto";
    font-weight: 700;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 4.25rem;
    padding: 0 0 0 4rem;
    @media (max-width: 1250px) {
      padding: 0;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-flow: column wrap;
    align-items: center;
    padding: 5rem 0;
`;
const Card = styled.div`
  width: 80%;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  background-color: #30303065;
  padding: 3rem;
  font-family: "Roboto";
  line-height: 1.2rem;
  letter-spacing: 0.5px;
`;
const Header = styled.div`
  font-size: 2rem;
  line-height: 2rem;
`;
const Body = styled.div`
`;
const Location = styled.p`
  font-size: .7rem;
  margin-top: -1rem;
`;

const AboutPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();

  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Saba Hossein - Portfolio"
        description="Full Stack Web Developer with over 9 years of experience and with a large set of supporting skills. Experienced in Typescript, Javascript, React, Gatsby, Adobe XD, UX/UI Design, Node.js, HTML, CSS, Styled Components, React Spring, AWS, Imgix, HeadLess CMS, and many more."
        keywords={["saba"]}
        url={props.location.pathname}
      />
      <Container>
         <Card>
            <Header>Summary</Header>
            <Body>Accomplished technologist and Agile product manager with over 10+ years of experience in managing complex infrastructures and leading cross-functional teams. Proven expertise in driving innovation within mobile technologies and ecosystem development, as well as in restaurant management systems. Adept at developing product strategies, streamlining processes, and enhancing organizational efficiency. Known for dynamic leadership, I excel in adapting to evolving project landscapes and consistently leading teams to surpass client expectations. Demonstrated ability to reduce operational costs, optimize user experiences, and deliver high-quality software solutions.</Body>
         </Card>
         <Card>
            <Header>Experience</Header>
            <Header style={{fontSize: "1.8rem"}}>Senior Product Manager</Header>
            <Location>eatOS | eigital. December 2023 - Present</Location>
            <Body>
              <ul style={{listStyle: "circle"}}>
              <li>Led cross-functional teams of up to 26 professionals, fostering a high-performance culture.</li>
              <li>Conceptualized and implemented new features based on customer pain points, enhancing product value proposition.</li>
              <li>Developed a comprehensive Product playbook, streamlining processes and improving organizational efficiency.</li>
              <li>Created processes and product documents for project and product managers and guided them through the implementation and execution phases, ensuring consistency, clarity, and adherence to best practices.</li>
              <li>Emphasized Lean management principles to eliminate waste, streamline workflows, and enhance value delivery, resulting in significant efficiency gains and cost savings.</li>
              <li>Conducted thorough testing and quality assurance checks on product features, ensuring stability and reliability prior to deployment.</li>
              <li>Contributed to cost reduction initiatives by implementing efficient tools and recruiting highly motivated talent.</li>
              </ul>
            </Body>
             <Header style={{fontSize: "1.8rem"}}>Technical Product Manager</Header>
            <Location>eatOS | epaisa. November 2022 - Present</Location>
            <Body>
              <ul style={{listStyle: "circle"}}>
              <li>Developed product strategies and roadmaps aligned with market demands and company objectives.</li>
              <li>Led a team of 6 Flutter developers, 3 testers, 2 designers, and a Scrum Master, ensuring effective collaboration and successful project delivery.</li>
              <li>Designed and implemented business logic for a Restaurant and Retail Management System, optimizing user experience.</li>
              <li>Provided exceptional customer support, driving high client satisfaction and long-term relationships.</li>
              <li>Managed custom deployments, incorporating tailored features to meet specific business needs, increasing client retention.</li>
              </ul>
            </Body>
             
            <Header style={{fontSize: "1.8rem"}}>Senior Program Manager - Product</Header>
            <Location>Baháʼí World Centre, Haifa, Israel. March 2019 - October 2022</Location>
            <Body>
              <ul style={{listStyle: "circle"}}>
                <li>Working as a Senior Program Officer in the Office of Media and Technology, I had responsibilities that included being the "Lead full-stack developer" and the "Technical Project Manager" due to my prior work experience.</li>
                <li>Multinational experience managing cross-functional delivery teams, as well as interfacing with executives and the technical team.</li>
                <li>One of my role and responsibilities was to oversee a team of 20 which included developers, QA, designers and social media team.</li>
                <li>Every project was managed using a hybrid model ( Waterfall and Agile methodology) </li>
                <li>As a Senior Program Officer, we used sprints to track our progress, bugs, timeline and goals for the project.</li>
                <li>Re-architected and Redesigned a complete website using latest framework to tailor the new standards of web.</li>
                <li>Trained multiple developers from different parts of the world to contribute for the project.</li>
                <li>Gathered data about project progress and communicated project dependencies and status to required stakeholders.</li>
                <li>Developed and mandated standards and processes for status reporting by various team leads, including close supervision, to ensure a high standard of reporting becomes part of the organizational culture.</li>
                <li>Quality control for reports submitted by various team leads for various projects and operational initiatives, as well as the preparation of executive summaries for every initiative.</li>
                <li>Captured new project requests and ensured each of those requests have enough relevant information that can aid in assessing the project’s priority.</li>
                <li>Trained staff who are commencing in the role of Program officer for IT projects.</li>
                <li>Built relationships with other departments of the organization enacting as a Department Interface and practiced collaborative business processes to manage new request expectations in light of the resource capacity within the department.</li>
                <li>Gathered and analyzed information from vendors to make a recommendation to Management about the most cost effective and most efficient means of contracting a workshop for staff in aiding the launch of a staff training program.</li>
              </ul>
            </Body>
            <Header style={{fontSize: "1.8rem"}}>Product Manager</Header>
            <Location>ForTech Technologies, Pune, India. 2016 March - 2019 March</Location>
            <Body>
              <ul style={{listStyle: "circle"}}>
                <li>Supported strategic initiatives that aligned with company objectives, driving sustained growth.</li>
                <li>Managed and mentored a team of department heads, ensuring cross-departmental collaboration and alignment.</li>
                <li>Oversaw budget planning and financial management, leading to a 20% reduction in operational costs.</li>
                </ul>
              <ul style={{listStyle: "circle"}}>
                <li>Gathered and analyzed information from stakeholders to create and Managed for multiple projects on various platforms.</li>
                <li>We used Waterfall and Agile methodologies to manage and deliver multiple clients facing projects.</li>
                <li>As a Porduct Manager, I had to oversee business operations which included research, developing designs, hiring freelancers, Project management,managing customer painpoint, budgeting, Translating design to code, managing code quality and testing of the applications.</li>
              </ul>
            </Body>
             <Header style={{fontSize: "1.8rem"}}>Software Developer</Header>
            <Location>ForTech Technologies, Pune, India. 2014 - 2016 March</Location>
            <Body>
              <ul style={{listStyle: "circle"}}>
                <li>I concentrated on Frontend and Backend technologies as a software engineer. Here, I discovered how to adapt my talents to projects and employ agile processes in accordance with best practises.</li>
              </ul>
            </Body>
            <Header style={{fontSize: "1.8rem"}}>Intern - Jr. Javascript developer</Header>
            <Location>Tribbianis Software Solutions Pvt Ltd, Pune, India. 2013 - 2014</Location>
            <Body>
              <ul style={{listStyle: "circle"}}>
                <li>I created frontend components for the carpool website, such as graphic elements for the homepage, the login page, and the feature section, while working as a junior Javascript developer for a company with clients in the EU.</li>
                <li>Interacted with multicontinental stakeholders to better understand the operation of the project while being supervised by a senior manager.</li>
                <li>Made design recommendations which were then implemented on the website.</li>
              </ul>
            </Body>
         </Card>
         <Card>
         <Header>
         <a href={sabahossein} download="Saba Hossein">Saba Hossein - Resume</a>
         </Header>
         </Card>
      </Container>
    </>
  )
}

export default AboutPage
